import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { SectionTemplate } from '../../components';
import mask from '../../images/blob-shape.svg';
import { media } from '../../utils/mixins';

export const CartSize = () => {
    const { dinning } = useStaticQuery(query);
    return (
        <SectionTemplate
            headingText="Increase Average"
            subHeadingText="Cart Size"
            paragraphText={`Boost customers' average cart sizes through CheaprEats'
            recommendation section, in which cross-selling
            post-purchase occurs to sell additional items to match
            with existing orders. With the use of Flasah Sales
            through push notification services, this allows for a
            reduction in food waste and an increase in revenue.`}
            imageContent={<Image fluid={dinning.childImageSharp.fluid} />}
        />
    );
};

const query = graphql`
    {
        dinning: file(relativePath: { eq: "dinning.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;

const Image = styled(Img)`
    mask: url(${mask});
    mask-size: cover;
    max-width: 26rem;
    max-height: 26rem;
    width: 100%;
    height: auto;
    ${media.tablet`
        max-width: 20rem;
        max-height: 20rem;
    `}
`;
