import React from 'react';
import styled from 'styled-components';
import { SmallText as S } from '@cheapreats/react-ui';
import { SectionTemplate, Text } from '../../components';
import { media } from '../../utils/mixins';

export const Table = () => {
    return (
        <SectionTemplate
            header={
                <Heading size="medium" as="h1" color="primary" align="center">
                    <span>
                        Quantitative Analysis from Pre-CheaprEats (2018) to Post
                    </span>
                    <span>CheaprEats (2019)</span>
                </Heading>
            }
            footer={
                <TableContainer>
                    <Row isFirst="true">
                        <Column bg="primary"></Column>
                        <Column>
                            <SmallText bold>Pre-CheaprEats</SmallText>
                        </Column>
                        <Column>
                            <SmallText bold>Post-CheaprEats</SmallText>
                        </Column>
                        <Column>
                            <SmallText bold>Gain (+) / Loss (-)</SmallText>
                        </Column>
                    </Row>
                    <Row>
                        <Column bg="primary">
                            <WhiteText>
                                Total Number of Orders Per Month
                            </WhiteText>
                        </Column>
                        <Column bg="grey" color="black">
                            <SmallText>No Data Shared</SmallText>
                        </Column>
                        <Column bg="grey" color="black">
                            <SmallText>67</SmallText>
                        </Column>
                        <Column bg="grey" color="black">
                            <SmallText>N / A</SmallText>
                        </Column>
                    </Row>
                    <Row>
                        <Column bg="primary">
                            <WhiteText>Average Order Size</WhiteText>
                        </Column>
                        <Column>
                            <SmallText>$5.50</SmallText>
                        </Column>
                        <Column>
                            <SmallText>$8.50</SmallText>
                        </Column>
                        <Column>
                            <SmallText>(+) 64.71%</SmallText>
                        </Column>
                    </Row>
                    <Row>
                        <Column bg="primary">
                            <WhiteText>Average Wait Time</WhiteText>
                        </Column>
                        <Column bg="grey" color="black">
                            <SmallText>6 Minutes</SmallText>
                        </Column>
                        <Column bg="grey" color="black">
                            <SmallText>3 Seconds</SmallText>
                        </Column>
                        <Column bg="grey" color="black">
                            <SmallText>(-) 99%</SmallText>
                        </Column>
                    </Row>
                    <Row>
                        <Column bg="primary">
                            <WhiteText>End of Day Food Waste</WhiteText>
                        </Column>
                        <Column>
                            <SmallText>$18 - 24 Lost</SmallText>
                        </Column>
                        <Column>
                            <SmallText>$9 - 15 Lost</SmallText>
                        </Column>
                        <Column>
                            <SmallText>(-) 50%</SmallText>
                        </Column>
                    </Row>
                </TableContainer>
            }
        />
    );
};

const SmallText = styled(S)`
    ${({ theme }) => `color: ${theme.colors.black};`}
`;
const WhiteText = styled(S)`
    ${({ theme }) => `color: ${theme.colors.background};`}
`;

const Heading = styled(Text)`
    flex-direction: column;
    display: flex;
    ${media.tablet`
        margin-top: ${({ theme }) => theme.spacing.container};
    `}
    ${({ theme, color }) =>
        color ? `color: ${theme.colors[color] || color};` : ''}
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    ${({ theme, isFirst }) =>
        isFirst
            ? `border:1px solid pink;border-top:2px solid ${theme.colors['primary']}`
            : ''}
`;
const Column = styled.div`
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6px;
    ${({ theme, bg }) =>
        bg ? `background-color: ${theme.colors[bg] || bg};` : ''}
    ${({ theme, bg, color }) => (bg ? `color: ${color || 'white'};` : '')}
`;

const TableContainer = styled.div`
    margin-top: 0.75rem;
`;
