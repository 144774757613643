import React from 'react';
import { Heading } from '@cheapreats/react-ui';
import { SectionTemplate } from '../../components';

export const Description = () => {
    return (
        <SectionTemplate
            header={
                <Heading type="h4" color="primary">
                    CheaprEats provides a bridge of communication between
                    customers and vendors providing students with a fast and
                    efficient delivery service for on the go, and allowing
                    vendors to operate at a higher efficiency rate.
                </Heading>
            }
        />
    );
};
