import React from 'react';
import {
    Landing,
    Description,
    OperationalEfficiency,
    CustomerSatisfaction,
    CartSize,
    Example,
    Table,
} from '../sections/client';
import { Layout } from '../components';

const ClientPage = () => (
    <Layout title="Client">
        <Landing />
        <Description />
        <OperationalEfficiency />
        <CustomerSatisfaction />
        <CartSize />
        <Example />
        <Table />
    </Layout>
);

export default ClientPage;
