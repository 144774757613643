import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { SectionTemplate } from '../../components';
import mask from '../../images/blob-shape.svg';
import { media } from '../../utils/mixins';

export const CustomerSatisfaction = () => {
    const { dinning } = useStaticQuery(query);
    return (
        <SectionTemplate
            headingText="Customer"
            subHeadingText="Satisfaction"
            paragraphText={`Create a new type of customer loyalty and customer
            acquisition through CheaprEats' social loyalty program
            by maximizing the relationship between existing
            customers and their friends. CheaprEats provides vendors
            with a platform to customize daily or late-night special
            deals and offer exclusive discounts on certain menu
            items. With this simple system, businesses can boost
            customer acquisition and customers can receive loyalty
            benefits that will continue to bring them back.`}
            imageContent={<Image fluid={dinning.childImageSharp.fluid} />}
            reverse
        />
    );
};

const query = graphql`
    {
        dinning: file(relativePath: { eq: "dinning.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;

const Image = styled(Img)`
    mask: url(${mask});
    mask-size: cover;
    max-width: 26rem;
    max-height: 26rem;
    width: 100%;
    height: auto;
    ${media.tablet`
        max-width: 20rem;
        max-height: 20rem;
    `}
`;
