import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { SectionTemplate } from '../../components';
import mask from '../../images/blob-shape.svg';
import { media } from '../../utils/mixins';

export const Landing = () => {
    const { dinning } = useStaticQuery(query);
    return (
        <SectionTemplate
            headingText="CheaprEats` Case Study"
            paragraphText="CheaprEats is built to provide vendors with a platform
            that offers higher operational efficiency, customer
            satisfaction, and increased average cart sizes."
            imageContent={<Image fluid={dinning.childImageSharp.fluid} />}
        />
    );
};

const query = graphql`
    {
        dinning: file(relativePath: { eq: "dinning.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;

const Image = styled(Img)`
    mask: url(${mask});
    mask-size: cover;
    max-width: 26rem;
    max-height: 26rem;
    width: 100%;
    height: auto;
    ${media.tablet`
        max-width: 20rem;
        max-height: 20rem;
    `}
`;
