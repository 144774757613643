import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { SectionTemplate } from '../../components';
import { media } from '../../utils/mixins';

export const Example = () => {
    const { dinning } = useStaticQuery(query);
    return (
        <SectionTemplate
            subHeadingText={`"CheaprEats has helped 
                    me get more orders at UTSC with 
                    their social food app that has 
                    owners that care!" - Nasir 
                    Alhuttam, Owner of Nasir's
                    Gourmet Hotdogs`}
            paragraphText={`Nasir's Gourmet Hotdogs switched to CheaprEats to boost
            customer acquisition and order sizes. Within a year of
            using CheaprEats, Nasir's Gourmet Hotdogs saw
            substantial increases in operational efficiency, average
            cart sizes as well as customer satisfaction.`}
            imageContent={<Image fluid={dinning.childImageSharp.fluid} />}
            reverse
        />
    );
};

const query = graphql`
    {
        dinning: file(relativePath: { eq: "dinning.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;

const Image = styled(Img)`
    clip-path: polygon(0 0, 0 100%, 60% 100%, 90% 0%);
    max-width: 26rem;
    max-height: 26rem;
    width: 100%;
    height: auto;
    ${media.tablet`
        max-width: 20rem;
        max-height: 20rem;
    `}
`;
